import { Link } from 'components/link';

interface ContactInfoProps {
  email?: string;
  phone?: string;
  product?: 'ed' | 'hrt';
}

// TODO: This should be refactored to get email and phone based on product
export default function ContactInfo(props: ContactInfoProps) {
  const { email, phone } = props;
  return (
    <div className='mt-4 flex flex-col space-y-4 md:mt-0 md:grid md:grid-cols-1 md:grid-rows-3 md:gap-y-4 md:space-y-0'>
      <div className='max-w-sm text-sm font-medium md:text-base md:font-normal'>
        809 Westmere Ave, Suite A, Charlotte, NC 28208
      </div>
      {email && (
        <div className='text-sm font-medium md:text-base md:font-semibold'>
          Email: <Link href={`mailto:${email}`}>{email}</Link>
        </div>
      )}
      <div className='text-sm font-medium md:text-base md:font-semibold'>
        Tel: <Link href={`tel:${phone}`}>{phone}</Link>
      </div>
    </div>
  );
}

ContactInfo.defaultProps = {
  product: 'hrt',
  email: '',
  phone: '',
};
