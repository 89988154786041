import { ReactComponent as PhoneIcon } from 'assets/red-phone.svg';
import { ReactComponent as MaleExcelLogoNew } from 'assets/male-excel-color.svg';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';

const phoneNumberVisual = '(833) 625-3392';
const phoneNumberFunctional = 'tel:833-625-3392';

export default function Header() {
  const largeScreen = useTailwindBreakpoints().sizeUp('md');

  return (
    <div className='m-2 flex justify-between md:my-4 md:ml-16 md:mr-12'>
      <MaleExcelLogoNew
        width={largeScreen ? '18rem' : '10rem'}
        height='100%'
      />
      <a href={phoneNumberFunctional} className='flex items-center'>
        <PhoneIcon
          className='mx-1'
          height='100%'
          width={largeScreen ? '1.5rem' : '1rem'}
        />
        <div className='flex flex-col'>
          {largeScreen && (
            <div className='text-lg font-semibold'>Have questions?</div>
          )}
          <div className='text-xs font-semibold text-scarlet md:text-base'>
            {largeScreen ? phoneNumberVisual : 'Call now'}
          </div>
        </div>
      </a>
    </div>
  );
}

Header.defaultProps = {
  product: 'hrt',
};
