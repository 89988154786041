import { CircularProgress, Grid, Container, Paper } from '@material-ui/core';
import { FullScreenLoadingProps } from './full-screen-loading-interface';

const FullScreenLoading = (props: FullScreenLoadingProps) => {
  const { width, maxWidth, padding, paperWrap, paperProps, height } = props;
  const circProgress = (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      direction='column'
      style={{
        height: '100vh',
      }}
    >
      <Grid item>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
  return (
    <Container
      disableGutters
      style={{
        width,
        maxWidth,
        padding,
        height,
      }}
    >
      {paperWrap && <Paper {...paperProps}>{circProgress}</Paper>}
      {!paperWrap && circProgress}
    </Container>
  );
};

FullScreenLoading.defaultProps = {
  width: '1074px',
  maxWidth: '92vw',
  height: '100vh',
  padding: 0,
  paperWrap: false,
  paperProps: {},
};

export default FullScreenLoading;
