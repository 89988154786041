import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import LegitScript from 'assets/LegitScript.svg';
import { ReactComponent as MELogo } from 'assets/male-excel-color.svg';
import { getBrandInfo } from 'utils/utils';
import LinkGroups from './link-groups';
import ContactInfo from './contact-info';

const { brandName } = getBrandInfo();

interface FooterProps {
  phone?: string;
  email?: string;
  product?: 'ed' | 'hrt';
}

const hrtCopyright =
  'Copyright 2023, Med Excel Clinic P.A. All Rights Reserved';
const edCopyright = `Copyright 2023, ${brandName} Medical, P.A. All Rights Reserved`;

export default function Footer(props: FooterProps) {
  const { email, product } = props;
  const largeScreen = useTailwindBreakpoints().sizeUp('md');
  return (
    <footer className='w-full'>
      {!largeScreen && <div className='h-1 bg-gray-300' />} {/* Gray Divider */}
      <div className='flex flex-col justify-between bg-white px-8 py-4 md:flex-row md:py-8 md:px-0 lg:justify-evenly'>
        <img
          src={LegitScript}
          alt='LegitScript Logo'
          className='mb-4 h-auto w-40 md:my-0 md:h-24 md:w-auto'
        />
        <LinkGroups />
        <ContactInfo email={email} phone='(833)625-3392' product={product} />
      </div>
      <div className='h-1 bg-slate-300' /> {/* Gray Divider */}
      <div className='flex flex-col bg-white px-8 py-6 md:flex-row md:justify-between md:pb-2'>
        <MELogo className='h-auto w-40 md:w-60' />
        <div className='text-sm'>
          {product === 'ed' && edCopyright}
          {product === 'hrt' && hrtCopyright}
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  phone: '(833)625-3392',
  email: 'support@maleexcel.com',
  product: 'hrt',
};
