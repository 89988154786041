import Header from 'components/layouts/header';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import Footer from './footer/footer';
import { LayoutProps } from './layouts-interfaces';

export default function MaleDefaultLayout(props: LayoutProps) {
  const { children, product, background } = props;
  const isSmallScreen = useTailwindBreakpoints().sizeDown('sm');
  const backgroundClass = isSmallScreen ? `${background}-small` : background;

  return (
    <div
      className={`flex min-h-screen flex-col content-around items-center ${backgroundClass}`}
    >
      <div className='w-full bg-white px-2 shadow-lg'>
        <Header product={product} />
      </div>
      <div className='min-h w-fix my-4 min-h-[calc(100vh-26rem)] max-w-[98vw] content-center p-0 md:my-8'>
        {children}
      </div>
      <Footer product={product} />
    </div>
  );
}

MaleDefaultLayout.defaultProps = {
  background: 'layout-bg-male-main',
};
