import { getBrandInfo } from 'utils/utils';

const linkGroupsMale = [
  {
    link: 'Terms and Conditions',
    url: 'https://maleexcel.com/terms-conditions/',
  },
  {
    link: 'Privacy Policy',
    url: 'https://maleexcel.com/privacy-policy/',
  },
  {
    link: 'Privacy Practices',
    url: 'https://maleexcel.com/privacy-practices/',
  },
  {
    link: 'Refund Policy',
    url: 'https://maleexcel.com/refund-policy/',
  },
];

const linkGroupsFemale = [
  {
    link: 'Terms and Conditions',
    url: 'https://femexcel.com/terms-conditions/',
  },
  {
    link: 'Privacy Policy',
    url: 'https://femexcel.com/privacy-policy/',
  },
  {
    link: 'Privacy Practices',
    url: 'https://femexcel.com/privacy-practices/',
  },
  {
    link: 'Refund Policy',
    url: 'https://femexcel.com/refund-policy/',
  },
];

const { isFemme } = getBrandInfo();

export default function LinkGroups() {
  const linkGroups = isFemme ? linkGroupsFemale : linkGroupsMale;

  return (
    <div className='grid grid-cols-2 grid-rows-3 gap-y-4 gap-x-12'>
      {linkGroups.map(({ link, url }) => (
        <a
          key={link}
          className='text-md font-medium md:text-xl md:font-normal'
          href={url}
        >
          {link}
        </a>
      ))}
    </div>
  );
}
