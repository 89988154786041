import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import { ReactComponent as FemExcelLogo } from 'assets/fem-excel-color.svg';
import LinkGroups from './link-groups';
import ContactInfo from './contact-info';

interface FooterProps {
  phone?: string;
  product?: 'ed' | 'hrt';
}

export default function FooterFemme(props: FooterProps) {
  const { phone, product } = props;
  const largeScreen = useTailwindBreakpoints().sizeUp('md');
  return (
    <footer className='w-full bg-white'>
      {!largeScreen && <div className='mt-8 h-0.5 bg-slate-300' />}{' '}
      {/* Gray Divider */}
      <div className='flex flex-col justify-between px-8 py-4 md:flex-row md:py-8 md:pl-16 md:pr-12'>
        <LinkGroups />
        <ContactInfo phone={phone} product={product} />
      </div>
      <div className='mx-auto h-0.5 bg-slate-300 md:ml-16 md:mr-12' />{' '}
      {/* Gray Divider */}
      <div className='flex flex-col px-8 py-6 md:mb-2 md:flex-row md:justify-between md:pl-16 md:pr-12'>
        <FemExcelLogo className='h-auto w-40 text-femexcel md:w-60' />
        <div className='text-sm'>
          Copyright 2024, Fem Excel, P.A. All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

FooterFemme.defaultProps = {
  phone: '(833)625-3392',
  product: 'hrt',
};
