import FemmeHeader from 'components/layouts/header-femme';
import FemmeFooter from './footer/footer-femme';
import { LayoutProps } from './layouts-interfaces';

export default function FemmeMainLayout(props: LayoutProps) {
  const { children, product, background } = props;
  return (
    <div
      className={`flex min-h-screen flex-col content-around items-center ${background}`}
    >
      <div className='w-full bg-white px-2 shadow-lg'>
        <FemmeHeader />
      </div>
      <div className='min-h my-4 min-h-[calc(100vh-26rem)] w-fit content-center p-0 md:my-8'>
        {children}
      </div>
      <FemmeFooter product={product} />
    </div>
  );
}

FemmeMainLayout.defaultProps = {
  background: 'bg-femme-gradient md:bg-femme-gradient-lg',
};
