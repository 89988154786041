import { ReactComponent as PhoneIcon } from 'assets/purple-phone.svg';
import { ReactComponent as FemExcelLogo } from 'assets/fem-excel-color.svg';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import { Link } from 'components/link';

export default function HeaderFemme() {
  const largeScreen = useTailwindBreakpoints().sizeUp('md');

  return (
    <div className='mx-4 my-2 flex md:items-end justify-between md:my-4 md:ml-16 md:mr-12'>
      <FemExcelLogo
        width={largeScreen ? '15rem' : '10rem'}
        height='100%'
        className='text-femexcel'
      />
      <Link href='tel:833-625-3392' className='flex items-center'>
        {!largeScreen && (
          <PhoneIcon className='mx-1 mt-0.5' height='100%' width='.8rem' />
        )}
        <div className='flex flex-col items-center'>
          {largeScreen && <div className='font-semibold'>Connect with us</div>}
          <div className='text-xs font-semibold text-femexcel-dark md:text-base '>
            833-625-3392
          </div>
        </div>
      </Link>
    </div>
  );
}
