import { useEffect } from 'react';
import { updateHRTPatient } from 'services/memo/memo-service';
import useErrorSnackbar from './use-error-snackbar';
import useRouter from './use-router';

export default function useMPID() {
  const { snackbarCatch } = useErrorSnackbar();
  const { queryParams, landingId } = useRouter();
  const { mpid } = queryParams;

  useEffect(() => {
    if (!mpid || !landingId) return;
    updateHRTPatient({ mpid, landingId }).catch(snackbarCatch);
  }, [landingId, mpid, snackbarCatch]);
}
